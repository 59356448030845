<template>
	<div v-show="isInit">
		<div class="summary_container">
			<div class="top">{{ navigatorTitle }}</div>
			<div class="container">
					<div id="zoomBox">
						<div class="summary_title">
							<div class="name" v-if="departInfo">{{ departInfo.departName }}
								<template v-if="detailInfo.orderType == 1">（预算单）</template>
								<template v-if="detailInfo.orderType == 2">（报价单）</template>
							</div>
							<div class="date" v-if="detailInfo">
								<template v-if="detailInfo.orderType == 1">
									日期：{{ detailInfo.createTime }}
								</template>
								<template v-if="detailInfo.orderType == 2">
									<span class="lf" style="margin-right: 10px">
										日期：{{ detailInfo.offerTime }}
									</span>
									<span>报价单号：{{ detailInfo.offerNo ? detailInfo.offerNo : "-" }}</span>
								</template>
							</div>
						</div>
						<div class="userinfo">
							<div class="title">客户信息</div>
							<div class="userinfo_cont" v-if="customerDetail">
								<div>
									公司名称：{{ customerDetail.departName || '-' }}<br />
									联系地址：{{ customerDetail.address }}
								</div>
								<div class="rt">
									联系人：{{ customerDetail.customerName }}<br />
									联系电话：{{ customerDetail.phone }}
								</div>
							</div>
						</div>
						<div class="userinfo" style="border-bottom: 0;">
							<div class="title">项目信息</div>
							<div class="userinfo_cont" v-if="projectInfo">
								<div>
									项目名称：{{ projectInfo.projectName }}<br />
									送货地址：{{ projectInfo.provinceName }}{{ projectInfo.cityName }}{{ projectInfo.districtName }}{{ projectInfo.address }}
								</div>
								<div class="rt">
									配送运费：<template v-if="projectInfo.distributionType == 1">运费到付</template>
									<template v-if="projectInfo.distributionType == 2">运费寄付</template><br />
									收货人：{{ projectInfo.uaRealName }}<br />
									联系电话：{{ projectInfo.uaMobile }}
								</div>
							</div>
						</div>
						<table border="0" cellpadding="0" cellspacing="0" class="mytable">
							<tr>
								<th style="width: 160rpx">产品名称</th>
								<th style="width: 140rpx">规格型号</th>
								<th style="width: 60rpx">单位</th>
								<th style="width: 50rpx">数量</th>
								<!-- <th style="width: 120rpx">出厂价￥</th> -->
								<th style="width: 120rpx">单件价￥</th>
								<th style="width: 100rpx">小计￥</th>
							</tr>
							<tbody>

								<tr v-for="(item, index) in productList" :key="index">
									<td>{{ item.productName }}</td>
									<template v-if="item.proType == 1 || item.proType == 3">
										<!-- 成品件 自定义商品 -->
										<td>{{ item.standardsName }}</td>
									</template>
									<template v-if="item.proType == 2">
										<!-- 加工件 -->
										<td>{{ handerNodeValueList(item.nodeValueList) }}</td>
									</template>
									<td>{{ item.unitName }}</td>
									<td>{{ item.orderNum }}</td>
									<!-- <td>{{ item.outFactoryPrice || "-" }}</td> -->
									<td class="td_bg">
										{{ item.unitPrice }}
									</td>
									<td>{{ (item.orderNum * item.unitPrice).toFixed(2) }}</td>
								</tr>
								<tr class="table_foot">
									<td colspan="3" style="text-align: left;padding-left: 40rpx;"><span class="txt">合计：</span>
									</td>
									<td>{{ totalCount }}</td>
									<td colspan="2" style="text-align: right;padding-right: 20rpx;">
										<span class="txt">￥{{ totalPrice.toFixed(2) }}</span>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="summary_f">
							<div class="lf" v-if="departInfo">
								<span class="name">{{ departInfo.departName }}</span>
								<div class="contact">
									联系人：{{ departInfo.realname }}，地址：{{ departInfo.address }}<br />
									电话：{{ departInfo.phone }} 传真：{{ departInfo.fax || "" }}
								</div>
								<div>
									经营范围：<br />
									<div v-if="departInfo.memo">
										{{ departInfo.memo }}
									</div>
								</div>
							</div>
							<div class="rt">
								<img class="qr" src="../assets/qr.jpg" />
							</div>
						</div>
					</div>
		</div>
		</div>
	</div>
</template>

<script>
import $http from "@/common/api/request.js"
import { EasyScroller } from 'easyscroller'
import {
	getQueryString,
	timeStampToYMD
} from "@/common/comm.js"
export default {
	name: "offer",
	data() {
		return {
			navigatorTitle: "",
			ordId: "",
			salt: "",
			isInit: false,
			dialogTxt: "修改的数据未保存<br />确定退出吗？",
			okText: "继续修改",
			cancelText: "不保存退出",
			resetText: "还原",
			isReset: false,
			userInfo: null,
			departInfo: null,
			projectInfo: null,
			customerDetail: null,
			detailInfo: null,
			productList: [],
			totalCount: 0,
			totalPrice: 0,
			pricePercentage: 0,
			scroller: null
		}
	},
	mounted() {
		let request = getQueryString()
		this.ordId = request["id"]
		this.salt = request["salt"]
		this.getOfferDetail()
		this.setScroller()
	},
	beforeDestroy() {
		if (this.scroller) this.scroller.destroy()
	},
	methods: {
		getOfferDetail() {
			if (this.ordId) {
				let params = {
					url: '/sys/order/preview?id=' + this.ordId + "&salt=" + this.salt,
					method: 'GET',
					header: {
						'Content-Type': 'application/json',
					}
				}
				$http.request(params).then((res) => {
					if (res.code == 200) {
						this.isInit = true
						this.detailInfo = res.result
						this.detailInfo = {...this.detailInfo, createTime: timeStampToYMD(res.result.createTime), offerTime: timeStampToYMD(res.result.offerTime)}
						this.customerDetail = res.result.customerInfoVO
						this.departInfo = res.result.userInfoVO
						this.projectInfo = res.result.orderAddressVO
						this.productList = this.handerProduct(res.result.orderProductVOList)
						if (res.result.orderType == 1) {
							this.navigatorTitle = '预算单'
						}
						if (res.result.orderType == 2) {
							this.navigatorTitle = '报价单' + (res.result.offerNo || "")
						}
						document.title = this.navigatorTitle
						this.calcCountAndPrice()
					}
				}).catch(() => { })
			}
		},
		setScroller() {
			const ele = document.querySelector('#zoomBox'); //缩放盒子
			this.scroller = new EasyScroller(ele, {
				scrollingX: true,
				scrollingY: true,
				zooming: true,
				minZoom: 1,    //最小缩放
				maxZoom: 4,    //最大缩放
				zoomLevel: 1, //初始值缩放
				bouncing: false
			});
		},
		calcCountAndPrice() {
			this.totalCount = 0
			this.totalPrice = 0
			this.productList.forEach((item) => {
				this.totalCount += item.orderNum
				this.totalPrice += item.orderNum * item.unitPrice
			})
		},
		handerProduct(orderProductVOList) {
			let tempArr = []
			orderProductVOList = orderProductVOList.filter((pro) => pro.isAttachProd == 0)
			orderProductVOList.forEach((product) => {
				return product.orderItemVOList.forEach((orderItem) => {
					tempArr.push({
						...orderItem,
						unitName: product.unitName,
						productName: product.productName,
						proType: product.proType
					})
				})
			})
			return tempArr
		},
		handerNodeValueList(nodeValueList) {
			let nodeStr = ""
			nodeValueList.forEach((item, index) => {
				if (index == nodeValueList.length - 1) {
					nodeStr += item.nodeValue
				} else {
					nodeStr += item.nodeValue + '*'
				}
			})
			return nodeStr
		},
		setPerNumber(flag) {
			if (flag == 'decrease') {
				this.pricePercentage = (Number(this.pricePercentage) - 10).toFixed(2)
				if (this.pricePercentage < 0) {
					this.pricePercentage = 0
				}
			}
			if (flag == 'plus') {
				this.pricePercentage = (Number(this.pricePercentage) + 10).toFixed(2)
			}
		}
	}
}
</script>
<style scoped>
.top {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 10;
	background-color: #fff;
	width: 100%;
	height: 44px;
	text-align: center;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.summary_container {
	margin-top: 44px;
	padding-top: 0;
}
.container {
	height: calc(100vh - 44px);
	overflow: hidden;
}
.tips {
	text-align: center;
	font-size: 10px;
	padding-bottom: 15px;
}

.summary_title {
	padding: 8px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #9F9F9F;
	border-bottom: 1px solid #9F9F9F;
	background-color: #fff;
}

.summary_title .name {
	font-size: 9px;
	font-weight: bold;
	flex: 1;
}

.summary_title .date {
	flex: 1;
	font-size: 6px;
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.summary_title .date .lf {
		margin-right: 30rpx;
	}
.userinfo {
	display: flex;
	flex-direction: column;
	padding: 8px 10px;
	border-bottom: 1px solid #9F9F9F;
	background-color: #fff;
}

.userinfo .title {
	font-weight: bold;
	font-size: 9px;
	padding-bottom: 8px;
}

.userinfo_cont {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	font-size: 6px;
}

.userinfo_cont .rt {
	text-align: right;
}

.mytable {
	width: 100%;
	background-color: #fff;
}

.mytable th {
	font-size: 6px;
	color: #000;
	font-weight: normal;
	text-align: center;
	padding: 7px;
	background-color: #F6F6F6;
	border-bottom: 1px solid #CFCFCF;
}

.mytable td {
	font-size: 6px;
	color: #000;
	font-weight: normal;
	text-align: center;
	padding: 7px;
	border-bottom: 1px solid #CFCFCF;
}

.table_foot td {
	background-color: #F6F6F6;
	padding: 10px 10px;
}

.table_foot .txt {
	font-weight: bolder;
}

.summary_f {
	display: flex;
	justify-content: space-between;
	font-size: 6px;
	padding: 10px 10px 24px 10px;
	border-bottom: 1px solid #9F9F9F;
	background-color: #fff;
}

.summary_f .lf {
	padding-right: 15px;
}

.summary_f .name {
	font-size: 9px;
	font-weight: bold;
}

.summary_f .contact {
	margin: 6px 0 10px 0;
}

.summary_f .rt {
	flex: 0 0 80px;
}

.qr {
	width: 80px;
	height: 80px;
}

.fbtn {
	width: 100%;
	height: 49px;
	position: fixed;
	left: 0;
	bottom: 0;
	color: #333;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}

.fbtn .lf {
	flex: 1;
	display: flex;
}

.fbtn .f_u {
	flex: 1;
	height: 49px;
	text-align: center;
	line-height: 49px;
	border-right: 1px solid #C3C3C3;
}

.fbtn .f_u:last-child {
	border-right: 0;
}

.fbtn .contract {
	width: 88px;
	height: 49px;
	background-color: #0072DC;
	color: #fff;
	text-align: center;
	line-height: 49px;
}

.fbtn .order {
	width: 88px;
	height: 49px;
	background-color: #F83144;
	color: #fff;
	text-align: center;
	line-height: 49px;
}

.transfer_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.transfer_box .cancel {
	text-align: right;
	font-size: 14px;
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
}

.transfer_box .icon {
	width: 50px;
	height: 50px;
	margin-bottom: 10px;
}

.transfer_main {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 14px;
}

.mask {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, .5);
	z-index: 21;
}
</style>